.privacyContainer {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.privacyNav {
  height: 8rem; /* 8rem for nav height */
}

.privacyViewContainer {
  color: #474747;
  padding: 4rem 7rem;
  margin: 0 8rem;
  display: flex;
  flex-direction: column;
  word-spacing: 0.1rem;
}

.privacyHeading {
  width: 100%;
  font-size: 2.5vw;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 20px;
}

.privacyTitle {
  font-size: 1.2vw;
  margin-top: 1rem;
  font-weight: 700;
}

#terms.privacyTitle {
  margin-top: 3rem;
}

.privacyBody {
  font-size: 1vw;
  font-weight: 300;
  line-height: 1.6vw;
  letter-spacing: 1px;
  margin: 1rem 0;
}

#content.privacyBody {
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0,1,0,1);
}

#content.privacyBody.show {
  margin: 1rem 0;
  height: auto;
  max-height: 9999px;
  transition: all 0.4s cubic-bezier(1,0,1,0);
}

#title.privacyBody {
  font-style: italic;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0,1,0,1);
}

#title.privacyBody.show {
  margin: 1rem 0;
  height: auto;
  max-height: 9999px;
  transition: all 0.4s cubic-bezier(1,0,1,0);
}

.privacyItem {
  margin-top: 2rem;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 20px;
}

.privacyItemHeading {
  font-size: 1.1vw;
  cursor: pointer;
}

.privacyBlur {
  filter: blur(2px);
  z-index: -1;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .privacyViewContainer {
    padding: 8rem 7rem;
    margin: 0 15rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .privacyViewContainer {
    padding: 2rem 7rem;
    margin: 0 5rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .privacyViewContainer {
    padding: 1rem 6rem;
    margin: 0 2rem;
    margin-bottom: 2rem;
  }

  .privacyHeading {
    width: 100%;
    font-size: 3.3vw;
    letter-spacing: 0.41px;
  }

  .privacyTitle {
    font-size: 2.2vw;
    letter-spacing: -0.24px;
  }

  .privacyBody {
    font-size: 1.5vw;
    line-height: 2.2vw;
    letter-spacing: -0.24px;
    margin: 1rem 0;
  }

  .privacyItemHeading {
    font-size: 1.6vw;
    letter-spacing: -0.24px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .privacyViewContainer {
    padding: 1rem 4rem;
    margin: 0 2rem;
    margin-bottom: 2rem;
  }

  .privacyHeading {
    width: 100%;
    font-size: 4.4vw;
    letter-spacing: 0.41px;
  }

  .privacyTitle {
    font-size: 2.2vw;
    letter-spacing: -0.24px;
  }

  .privacyBody {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: -0.24px;
    margin: 1rem 0;
  }

  .privacyItemHeading {
    font-size: 2.2vw;
    letter-spacing: -0.24px;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .privacyViewContainer {
    padding: 1rem 1.2rem;
    margin: 0 0 2rem 0;
  }

  .privacyHeading {
    width: 100%;
    font-size: 6vw;
    letter-spacing: 0.41px;
  }

  .privacyTitle {
    font-size: 4vw;
    letter-spacing: -0.24px;
  }

  .privacyBody {
    font-size: 3.5vw;
    line-height: 5vw;
    letter-spacing: -0.24px;
    margin: 1rem 0;
  }

  #content.privacyBody.show {
    margin: 1rem 0;
  }

  .privacyItem {
    margin-top: 1.5rem;
  }

  .privacyItemHeading {
    font-size: 3.7vw;
    line-height: 5vw;
    display: flex; 
    padding-bottom: 0px;
    letter-spacing: -0.24px;
    font-weight: 400;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .privacyNav {
    height: 6rem;
  }

  .privacyViewContainer {
    padding: 1rem;
    margin: 0 0 2rem 0;
  }

  .privacyHeading {
    width: 100%;
    font-size: 6vw;
    letter-spacing: 0.41px;
  }

  .privacyTitle {
    font-size: 4vw;
    letter-spacing: -0.24px;
  }

  .privacyBody {
    font-size: 3.5vw;
    line-height: 5vw;
    letter-spacing: -0.24px;
    margin: 1rem 0;
  }

  #content.privacyBody.show {
    margin: 1.5rem 0 0.5rem 0;
  }

  .privacyItem {
    margin-top: 1.5rem;
  }

  .privacyItemHeading {
    font-size: 3.7vw;
    line-height: 5vw;
    display: flex; 
    padding-bottom: 0px;

    letter-spacing: -0.24px;
    font-weight: 400;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .privacyNav {
    height: 3rem;
  }

  .privacyViewContainer {
    padding: 0.7rem;
    margin: 0 0 2rem 0;
  }

  .privacyHeading {
    width: 100%;
    font-size: 6vw;
    letter-spacing: 0.41px;
  }

  .privacyTitle {
    font-size: 4.5vw;
    letter-spacing: -0.24px;
  }

  .privacyBody {
    font-size: 4vw;
    line-height: 6vw;
    letter-spacing: -0.24px;
    margin: 0.5rem 0;
  }

  #content.privacyBody.show {
    margin: 1rem 0 0 0;
  }

  .privacyItem {
    margin-top: 1.5rem;
  }

  .privacyItemHeading {
    font-size: 4.5vw;
    line-height: 5vw;
    display: flex; 
    padding-bottom: 0px;
    letter-spacing: -0.24px;
    font-weight: 400;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .resGrass {
    flex-direction: column;
    width: 85%;
  }

  #bottom.captionContainer {
    top: auto;
    position: relative;
    width: 100%;
    color: #474747;
  }

  #bottom.servCaptionTitle {
    font-size: 2.25vw;
    font-weight: 700;
    letter-spacing: -0.24px;
  }

  #bottom.servCaption {
    font-size: 1.5vw;
    line-height: 2.2vw;
    font-weight: 300;
    letter-spacing: -0.24px;
  }

  .contractBoxContainer {
    position: relative;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .contractBox {
    width: 100%;
    height: 13vw;
    background-color: #f7f7f7;
    border: 1px solid #707070;
  }

  .contractParagraph {
    color: #474747;
    width: 85%;
    gap: 1.5vw;
  }

  .contractTitle {
    font-size: 1.6vw;
  }

  .contractCaption {
    font-size: 1.5vw;
    line-height: 2.2vw;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .resGrass {
    flex-direction: column;
    width: 85%;
  }

  #bottom.captionContainer {
    top: auto;
    position: relative;
    width: 100%;
    color: #474747;
  }

  #bottom.servCaptionTitle {
    font-size: 3vw;
    font-weight: 700;
    letter-spacing: -0.24px;
  }

  #bottom.servCaption {
    font-size: 2vw;
    line-height: 3vw;
    font-weight: 300;
    letter-spacing: -0.24px;
  }

  .contractBoxContainer {
    position: relative;
    gap: 2rem;
    margin-top: 2rem;
  }

  .contractBox {
    width: 100%;
    height: 15vw;
    background-color: #f7f7f7;
    border: 1px solid #707070;
  }

  .contractParagraph {
    color: #474747;
    width: 85%;
    gap: 1.5vw;
  }

  .contractTitle {
    font-size: 2.2vw;
  }

  .contractCaption {
    font-size: 2vw;
    line-height: 3vw;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .resGrass {
    flex-direction: column;
    width: 80%;
  }

  #bottom.captionContainer {
    top: auto;
    position: relative;
    width: 100%;
    color: #474747;
  }

  #bottom.servCaptionTitle {
    font-size: 5vw;
    line-height: 5vw;
    font-weight: 700;
    letter-spacing: -0.24px;
  }

  #bottom.servCaption {
    font-size: 3vw;
    line-height: 5vw;
    font-weight: 300;
    letter-spacing: -0.24px;
    margin-top: 1rem;
  }

  .contractBoxContainer {
    position: relative;
    gap: 2rem;
    margin-top: 2rem;
  }

  .contractBox {
    width: 100%;
    height: 30vw;
    background-color: #f7f7f7;
    border: 1px solid #707070;
  }

  .contractParagraph {
    color: #474747;
    width: 85%;
    gap: 3vw;
  }

  .contractTitle {
    font-size: 4vw;
  }

  .contractCaption {
    font-size: 3vw;
    line-height: 5vw;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .resGrass {
    flex-direction: column;
    width: 80%;
  }

  #bottom.captionContainer {
    top: auto;
    position: relative;
    width: 100%;
    color: #474747;
  }

  #bottom.servCaptionTitle {
    font-size: 5vw;
    font-weight: 700;
    letter-spacing: -0.24px;
  }

  #bottom.servCaption {
    font-size: 3.5vw;
    line-height: 5vw;
    font-weight: 300;
    letter-spacing: -0.24px;
    margin-top: 1rem;
  }

  .contractBoxContainer {
    position: relative;
    gap: 2rem;
    margin-top: 2rem;
  }

  .contractBox {
    width: 100%;
    height: 30vw;
    height: 30dvw;
    background-color: #f7f7f7;
    border: 1px solid #707070;
  }

  .contractParagraph {
    color: #474747;
    width: 85%;
    gap: 3vw;
  }

  .contractTitle {
    font-size: 4vw;
  }

  .contractCaption {
    font-size: 3.5vw;
    line-height: 5vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .resGrass {
    flex-direction: column;
    width: 100%;
  }

  #bottom.captionContainer {
    top: auto;
    position: relative;
    width: 100%;
    color: #474747;
  }

  #bottom.servCaptionTitle {
    font-size: 5vw;
    font-weight: 700;
    letter-spacing: -0.24px;
  }

  #bottom.servCaption {
    font-size: 4vw;
    line-height: 5vw;
    font-weight: 300;
    letter-spacing: -0.24px;
    margin-top: 1rem;
  }

  .contractBoxContainer {
    position: relative;
    gap: 1.5rem;
    margin-top: 1rem;
  }

  .contractBox {
    width: 100%;
    height: 40vw;
    height: 40dvw;
    background-color: #f7f7f7;
    border: 1px solid #707070;
  }

  .contractParagraph {
    color: #474747;
    width: 82%;
    gap: 3vw;
  }

  .contractTitle {
    font-size: 4.5vw;
  }

  .contractCaption {
    font-size: 4vw;
    line-height: 6vw;
  }
}
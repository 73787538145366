.scrollTop {
    background-color: black;
    color: white;
    margin: 1.3rem;
    cursor: pointer;
    position: sticky;
    transition: opacity 0.4s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}
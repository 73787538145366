.contactContainer {
  color: #474747;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactImg {
  height: 100rem;
  width: 100%;
  object-fit: cover;
}

.navigateButton {
  font-size: 0.8vw;
  font-weight: 400;
  letter-spacing: 1px;
  color: #222222;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  width: 11vw;
  height: 2.75vw;
  border-radius: 13% 13% 13% 13% / 50% 50% 50% 50%;
}

.navigateButton.contactButton{
  font-size: 1.3rem;
  width: 13rem;
  height: 3.25rem;
}

#gray.navigateButton {
  transition: 0.5s ease;
  background: linear-gradient(to left, #e8e6e3 50%, #222222 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

#lightgray.navigateButton {
  font-weight: 500;
  background-color: #222222;
  color: #ffffff;
}

#gray.navigateButton:hover {
  outline: 0;
  color: #e8e6e3;
  background-position: left bottom;
}

#gray.navigateButton:active,
button:active {
  outline: 0;
}

.contactFormContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  top: 9rem;
  width: 50rem;
  height: auto;
  padding-bottom: 3rem;
  border-radius: 5%;
}

.contactFormTitle {
  font-size: 2rem;
  font-weight: bold;
  position: relative;
  top: 3rem;
}

.contactFormSubTitle {
  font-size: 1rem;
  position: relative;
  top: 3.5rem;
}

.contactFormFields {
  margin-top: 7rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  height: 35%;
  column-gap: 8rem;
  row-gap: 1.5rem;
}

.requiredField {
  position: relative;
  top: 6rem;
  right: 11rem;
}

.requiredField::before {
  content: "* ";
  color: red;
}

.contactCommentForm {
  font-size: 1.35rem;
  font-weight: bold;
  margin-top: 1rem;
}

.contactCommentForm::after {
  content: " *";
  color: red;
}

.contactCaptButtonCont {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.contactButtonAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.contactBlur {
  filter: blur(2px);
  z-index: -1;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .contactImg {
    height: 110rem;
  }

  .navigateButton {
    width: 10vw;
    height: 2.5vw;
  }

  .contactFormContainer {
    top: 12rem;
    width: 48rem;
    padding-bottom: 3rem;
  }

  .navigateButton.contactButton{
    font-size: 1.4rem;
    width: 14rem;
    height: 3.5rem;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .contactImg {
    height: 95rem;
  }

  .contactFormContainer {
    top: 9rem;
    width: 45rem;
  }

  .contactFormTitle {
    font-size: 1.5rem;
  }

  .contactFormSubTitle {
    font-size: 0.9rem;
  }

  .requiredField {
    font-size: 0.75rem;
  }

  .contactFormFields {
    width: 70%;
    height: auto;
    margin-top: 6rem;
    column-gap: 9rem;
    row-gap: 0.5rem;
  }

  .contactCommentForm {
    font-size: 1.1rem;
    margin-left: 0;
  }

  .navigateButton.contactButton{
    font-size: 1.1rem;
    width: 11rem;
    height: 2.75rem;
  }

  .contactButtonAlign {
    gap: 1rem;
  }

  .contactCaptButtonCont {
    margin-top: 0.5rem;
  }

  .captchaWrapper {
    transform: scale(0.85);
  }

  .contactFormInput .MuiInput-input {
    font-size: 0.9rem;
  }

  .contactFormInput .MuiInputLabel-standard {
    font-size: 0.9rem;
  }

  .contactFormInput .MuiInputLabel-outlined {
    font-size: 0.9rem;
  }

  .contactFormInput .MuiFormControlLabel-label {
    font-size: 0.85rem;
  }

  .contactFormInput .MuiFormLabel-asterisk {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .contactImg {
    height: 95rem;
  }

  .contactFormContainer {
    width: 35rem;
    padding-bottom: 1rem;
  }

  .contactFormFields {
    margin-top: 5rem;
    width: 60%;
    height: auto;
    column-gap: 3.5rem;
    row-gap: 1rem;
  }

  .contactFormTitle {
    font-size: 1.4rem;
    top: 2rem;
  }

  .contactFormSubTitle {
    font-size: 0.75rem;
    top: 2.5rem;
  }

  .requiredField {
    font-size: 0.75rem;
    top: 4.5rem;
  }

  .contactCommentForm {
    font-size: 1rem;
    margin-left: 0;
  }

  .contactFormInput .MuiInput-input {
    font-size: 0.75rem;
  }

  .contactFormInput .MuiInputLabel-standard {
    font-size: 0.8rem;
  }

  .contactFormInput .MuiInputLabel-outlined {
    font-size: 0.7rem;
  }

  .contactFormInput .MuiFormControlLabel-label {
    font-size: 0.75rem;
  }

  .contactFormInput .MuiFormLabel-asterisk {
    font-size: 0.8rem;
  }

  .contactButtonAlign {
    gap: 1rem;
  }

  .contactCaptButtonCont {
    margin-top: 0.5rem;
  }

  #mobile.navigateButton {
    background-color: #222222;
    font-size: 1.4vw;
    width: 11rem;
    height: 2.75rem;
  }

  #lightgray.navigateButton {
    font-size: 1.3vw;
    width: 9rem;
    height: 2.25rem;
  }
  
  #gray.navigateButton {
    transition: 0.5s ease;
    background: linear-gradient(to left, #222222 50%, #e8e6e3 50%);
    background-size: 200% 100%;
    background-position: right bottom;
  }
  
  #gray.navigateButton:hover {
    color: #222222;
  }

  .navigateButton.contactButton{
    font-size: 0.9rem;
    width: 10rem;
    height: 2.5rem;
    margin-bottom: 3rem;
  }

  .captchaWrapper {
    transform: scale(0.7);
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  #mobile.navigateButton {
    background-color: #e8e6e3;
    font-size: 1.3vw;
    width: 18vw;
    height: 4.5vw;
  }

  .contactImg {
    height: 95rem;
  }

  .contactFormContainer {
    width: 35rem;
    padding-bottom: 1rem;
  }

  .contactFormFields {
    margin-top: 5rem;
    width: 60%;
    height: auto;
    column-gap: 3.5rem;
    row-gap: 1rem;
  }

  .contactFormTitle {
    font-size: 1.4rem;
    top: 2rem;
  }

  .contactFormSubTitle {
    font-size: 0.75rem;
    top: 2.5rem;
  }

  .requiredField {
    font-size: 0.75rem;
    top: 4.5rem;
  }

  .contactCommentForm {
    font-size: 1rem;
    margin-left: 0;
  }

  .contactFormInput .MuiInput-input {
    font-size: 0.75rem;
  }

  .contactFormInput .MuiInputLabel-standard {
    font-size: 0.8rem;
  }

  .contactFormInput .MuiInputLabel-outlined {
    font-size: 0.7rem;
  }

  .contactFormInput .MuiFormControlLabel-label {
    font-size: 0.75rem;
  }

  .contactFormInput .MuiFormLabel-asterisk {
    font-size: 0.7rem;
  }

  .contactButtonAlign {
    gap: 1rem;
  }

  .contactCaptButtonCont {
    margin-top: 0.5rem;
  }

  .navigateButton.contactButton{
    font-size: 0.9rem;
    width: 10rem;
    height: 2.5rem;
    margin-bottom: 3rem;
  }

  .captchaWrapper {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  #mobile.navigateButton {
    background-color: #e8e6e3;
    border-radius: 10% 10% 10% 10% / 50% 50% 50% 50%;
    font-size: 2.5vw;
    width: 40vw;
    height: 8vw;
  }

  .contactImg {
    height: 120rem;
  }

  .contactFormContainer {
    width: 25rem;
    padding-bottom: 1rem;
    border-radius: 30px 30px 30px 30px;
  }

  .contactFormTitle {
    font-size: 1.5rem;
    top: 2rem;
  }

  .contactFormSubTitle {
    font-size: 1rem;
    top: 2.5rem;
    padding: 0 3rem;
  }

  .requiredField {
    font-size: 0.85rem;
    position: relative;
    top: 4rem;
    right: auto;
  }

  .contactFormFields {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }

  .contactCommentForm {
    font-size: 1.2rem;
    margin-left: 0;
  }

  .contactFormInput .MuiInput-input {
    font-size: 1rem;
  }

  .contactFormInput .MuiInputLabel-standard {
    font-size: 1rem;
  }

  .contactFormInput .MuiInputLabel-outlined {
    font-size: 0.85rem;
  }

  .contactFormInput .MuiFormControlLabel-label {
    font-size: 1rem;
  }

  .contactFormInput .MuiFormLabel-asterisk {
    font-size: 0.85rem;
  }

  .contactButtonAlign {
    gap: 1rem;
  }

  .contactCaptButtonCont {
    margin-top: 0.5rem;
  }

  .navigateButton.contactButton{
    font-size: 0.9rem;
    width: 10rem;
    height: 2.5rem;
    margin-bottom: 3rem;
  }

  .captchaWrapper {
    transform: none;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  #mobile.navigateButton {
    background-color: #e8e6e3;
    border-radius: 10% 10% 10% 10% / 50% 50% 50% 50%;
    font-size: 2.5vw;
    width: 40vw;
    height: 8vw;
  }

  .contactImg {
    height: 120rem;
  }

  .contactFormContainer {
    width: 22rem;
    padding-bottom: 1rem;
    border-radius: 30px 30px 30px 30px;
  }

  .contactFormTitle {
    font-size: 1.3rem;
    top: 2rem;
  }

  .contactFormSubTitle {
    font-size: 0.9rem;
    top: 2.5rem;
    padding: 0 2.5rem;
  }

  .requiredField {
    font-size: 0.85rem;
    position: relative;
    top: 4rem;
    right: auto;
  }

  .contactFormFields {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }

  .contactCommentForm {
    font-size: 1.2rem;
    margin-left: 0;
  }

  .contactFormInput .MuiInput-input {
    font-size: 1rem;
  }

  .contactFormInput .MuiInputLabel-standard {
    font-size: 1rem;
  }

  .contactFormInput .MuiInputLabel-outlined {
    font-size: 0.7rem;
  }

  .contactFormInput .MuiFormControlLabel-label {
    font-size: 1rem;
  }

  .contactFormInput .MuiFormLabel-asterisk {
    font-size: 0.85rem;
  }

  .contactButtonAlign {
    gap: 1rem;
  }

  .contactCaptButtonCont {
    margin-top: 0.5rem;
  }

  .navigateButton.contactButton{
    font-size: 0.9rem;
    width: 10rem;
    height: 2.5rem;
    margin-bottom: 3rem;
  }

  .captchaWrapper {
    transform: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  #mobile.navigateButton {
    background-color: #e8e6e3;
    border-radius: 10% 10% 10% 10% / 50% 50% 50% 50%;
    font-size: 3vw;
    width: 40vw;
    height: 8vw;
  }

  .contactImg {
    height: 110rem;
  }

  .contactFormContainer {
    width: 19rem;
    top: 5rem;
    padding-bottom: 1rem;
    border-radius: 30px 30px 30px 30px;
  }

  .contactFormTitle {
    font-size: 1.1rem;
    top: 2rem
  }

  .contactFormSubTitle {
    font-size: 0.8rem;
    top: 2.5rem;
    padding: 0 2rem;
  }

  .requiredField {
    font-size: 0.75rem;
    position: relative;
    top: 4rem;
    right: auto;
  }

  .contactFormFields {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }

  .contactCommentForm {
    font-size: 1.1rem;
    margin-left: 0;
  }

  .contactFormInput .MuiInput-input {
    font-size: 0.8rem;
    width: 12rem;
  }

  .contactFormInput .MuiInputLabel-standard {
    font-size: 0.8rem;
  }

  .contactFormInput .MuiInputLabel-outlined {
    font-size: 0.6rem;
  }

  .contactFormInput .MuiFormControlLabel-label {
    font-size: 0.8rem;
  }

  .contactFormInput .MuiFormLabel-asterisk {
    font-size: 0.75rem;
  }

  .contactButtonAlign {
    gap: 1rem;
  }

  .contactCaptButtonCont {
    margin-top: 0.5rem;
  }

  .navigateButton.contactButton{
    font-size: 0.9rem;
    width: 10rem;
    height: 2.5rem;
    margin-bottom: 3rem;
  }

  .captchaWrapper {
    transform: scale(0.8);
  }
}
.galleryFlex {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.galleryCaptionContainer {
  position: absolute;
  color: white;
  bottom: 26vh;
  left: 9vw;
  display: flex;
  flex-direction: column;
}

.galleryCaptionTitle {
  font-size: 2.5vw;
  font-weight: 700;
  letter-spacing: 2px;
}

.gallerySubCaption {
  font-size: 1.2vw;
  margin-top: 0.5rem;
  letter-spacing: 1px;
}

.galleryContainer {
  padding: 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 3vw;
  row-gap: 2vw;
}

.galleryBoxH {
  width: 25vw;
  height: 16vw;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
}

.galleryBoxH:hover {
  filter: brightness(40%);
}

.galleryCaption {
  position: absolute;
  font-size: 0.85vw;
  bottom: 5%;
  left: 5%;
  color: #ffffff;
  z-index: 1;
}

.galleryCaption:hover ~ .galleryBoxH {
  filter: brightness(40%);
}

.galleryBlur {
  filter: blur(2px);
  z-index: -1;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .galleryCaptionContainer {
    bottom: 25vh;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .galleryCaptionContainer {
    top: auto;
    bottom: 20vh;
    left: 10vw;
  }

  .gallerySubCaption {
    font-weight: 700;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .galleryCaptionContainer {
    top: 65vh;
    left: 20vw;
  }

  .galleryCaptionContainer.blur {
    filter: blur(2px);
    top: 65vh;
  }

  .galleryCaptionTitle {
    font-size: 3.3vw;
    letter-spacing: 0.41px;
  }

  .gallerySubCaption {
    font-size: 1.5vw;
    letter-spacing: 0.41px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .galleryCaptionContainer {
    top: 33vh;
    top: 33dvh;
  }

  .galleryCaptionContainer.blur {
    filter: blur(2px);
    top: 33vh;
    top: 33dvh;
  }

  #gallery.heroImg {
    width: auto;
  }

  .galleryCaptionTitle {
    font-size: 4.4vw;
    letter-spacing: 0.41px;
  }

  .gallerySubCaption {
    font-size: 2vw;
    letter-spacing: 0.41px;
  }

  .galleryContainer {
    padding: 4rem;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .galleryCaptionContainer {
    top: 35vh;
    top: 35dvh;
  }

  .galleryCaptionContainer.blur {
    filter: blur(2px);
    top: 35vh;
    top: 35dvh;
  }

  #gallery.heroImg {
    object-position: 90%;
  }

  .galleryCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }

  .gallerySubCaption {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  .galleryContainer {
    padding: 2rem;
    column-gap: 3vw;
    row-gap: 2vw;
  }

  .galleryBoxH {
    width: 40vw;
    width: 40dvw;
    height: 30vw;
    height: 30dvw;
  }

  .galleryBoxH:hover {
    filter: none;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .galleryCaptionContainer {
    top: 35vh;
    top: 35dvh;
  }

  .galleryCaptionContainer.blur {
    filter: blur(2px);
    top: 35vh;
    top: 35dvh;
  }

  #gallery.heroImg {
    object-position: 90%;
  }

  .galleryCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }

  .gallerySubCaption {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  .galleryContainer {
    padding: 1.75rem;
    column-gap: 3vw;
    row-gap: 3vw;
  }

  .galleryBoxH {
    width: 40vw;
    width: 40dvw;
    height: 30vw;
    height: 30dvw;
  }

  .galleryBoxH:hover {
    filter: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .galleryCaptionContainer {
    top: 32vh;
    top: 32dvh;
  }

  .galleryCaptionContainer.blur {
    filter: blur(2px);
    top: 32vh;
    top: 32dvh;
  }

  #gallery.heroImg {
    object-position: 90%;
  }

  .galleryCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }

  .gallerySubCaption {
    font-size: 3.5vw;
    letter-spacing: 0.41px;
    margin-top: 0.25rem;
  }

  .galleryContainer {
    padding: 1rem;
    column-gap: 3vw;
    row-gap: 3vw;
  }

  .galleryBoxH {
    width: 40vw;
    width: 40dvw;
    height: 30vw;
    height: 30dvw;
  }

  .galleryBoxH:hover {
    filter: none;
  }
}

.homeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homePicContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.homePicContainer.blur {
    filter: blur(2px);
    z-index: -1;
}

.homePic {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}

.homeCaptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    color: #e8e6e3;
    top: 11vw;
    gap: 1rem;
}

.homeCaptionTitle {
    font-size: 2.5vw;
    font-weight: 900;
    letter-spacing: 3px;
}

.homeSubCaption {
    font-size: 1.2vw;
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 300;
}

#margin.homeSubCaption {
    margin-bottom: 3rem;
    margin-top: -0.5rem;
}

.homeButtons {
    display: flex;
    flex-direction: row;
    gap: 5rem;
}

.copyright {
    color: #ffffff;
    font-weight: 300;
    font-size: 0.7vw;
    position: absolute;
    bottom: 1rem;
    word-spacing: 2px;
    letter-spacing: 1px;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
    .homeCaptions {
        top: 11vw;
    }

    .homeCaptionTitle {
        font-size: 2.2vw;
        letter-spacing: 3px;
    }
    
    .homeSubCaption {
        font-size: 1vw;
        letter-spacing: 1px;
        word-spacing: 2px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
    .homeCaptions {
        top: 11vw;
    }

    #margin.homeSubCaption {
        margin-bottom: 1.5rem;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .homeCaptions {
        top: 23vh;
    }

    .homeCaptionTitle {
        font-size: 3.3vw;
        letter-spacing: 1.5px;
    }

    .homeSubCaption {
        font-size: 1.5vw;
        word-spacing: 1px;
        letter-spacing: 0.41px;
    }

    #margin.homeSubCaption {
        margin-bottom: 1rem;
    }

    .copyright {
        font-size: 1vw;
        font-weight: 400;
        bottom: 0.5rem;
        word-spacing: 1px;
    }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
    .homePic {
        height: 100dvh;
        object-position: 32%;
    }

    .homeCaptions {
        top: 28vh;
        top: 28dvh;
        gap: 0.8rem;
    }

    .homeCaptionTitle {
        font-size: 3.5vw;
        letter-spacing: 1.5px;
    }

    .homeSubCaption {
        font-size: 2vw;
        word-spacing: 1px;
        letter-spacing: 0.5px;
    }

    #margin.homeSubCaption {
        margin-bottom: .5rem;
    }

    .homeButtons {
        gap: 4rem;
    }

    .copyright {
        word-spacing: 1px;
        font-size: 1.3vw;
        bottom: 1rem;
        font-weight: 400;
    }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
    .homePic {
        height: 100dvh;
        object-position: 34%;
    }

    .homeCaptions {
        top: 23vh;
        top: 23dvh;
        gap: 1.25rem;
    }

    .mobileHomeAlign {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;
    }

    .homeCaptionTitle {
        font-size: 6vw;
        letter-spacing: 1.5px;
    }

    .homeSubCaption {
        font-size: 3vw;
        word-spacing: 1px;
        letter-spacing: 0.5px;
    }

    #margin.homeSubCaption {
        margin-bottom: 4vh;
        margin-bottom: 4dvh;
    }

    .homeButtons {
        flex-direction: column;
        gap: 1.5rem;
    }

    .copyright {
        word-spacing: 1px;
        font-size: 2vw;
        bottom: 0.5rem;
        font-weight: 400;
    }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
    .homePic {
        height: 100dvh;
        object-position: 34%;
    }

    .homeCaptions {
        top: 20vh;
        top: 20dvh;
        gap: 1.25rem;
    }

    .mobileHomeAlign {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;
    }

    .homeCaptionTitle {
        font-size: 6vw;
        letter-spacing: 1.5px;
    }

    .homeSubCaption {
        font-size: 3vw;
        word-spacing: 1px;
        letter-spacing: 0.5px;
    }

    #margin.homeSubCaption {
        margin-bottom: 5vh;
        margin-bottom: 5dvh;
    }

    .homeButtons {
        flex-direction: column;
        gap: 1.5rem;
    }

    .copyright {
        word-spacing: 1px;
        font-size: 2vw;
        bottom: 0.5rem;
        font-weight: 400;
    }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
    .homePic {
        height: 100dvh;
        object-position: 33%;
    }

    .homeCaptions {
        top: 13vh;
        top: 13dvh;
        gap: 1rem;
    }

    .mobileHomeAlign {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;
    }

    .homeCaptionTitle {
        font-size: 6vw;
        letter-spacing: 1.5px;
    }

    .homeSubCaption {
        font-size: 3.5vw;
        word-spacing: 1px;
        letter-spacing: 0.5px;
    }

    #margin.homeSubCaption {
        margin-bottom: 4vh;
        margin-bottom: 4dvh;
    }

    .homeButtons {
        flex-direction: column;
        gap: 1.5rem;
    }

    .copyright {
        word-spacing: 1px;
        font-size: 2vw;
        bottom: 0.5rem;
        font-weight: 400;
    }
}
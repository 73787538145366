@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.servicesNavContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.heroImgContainer {
  height: 92vh;
}

.heroImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.servicesContainer {
  margin: 5rem 7rem;
  color: #474747;
  font-weight: 300;
  font-size: 1vw;
  word-spacing: 0.1rem;
  line-height: 2.5vw;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.arrow {
  color: #a5a5a5;
  cursor: pointer;
  position: absolute;
  font-size: 2.5vw;
  z-index: 1;
}

.next {
  right: -5%;
  top: 50%;
}

.prev {
  left: -5%;
  top: 50%;
}

.servicesBlur {
  filter: blur(2px);
  z-index: -1;
}

.captionContainer {
  position: absolute;
  color: white;
  bottom: 26vh;
  left: 9vw;
}

#comm.captionContainer {
  width: 32%;
}

#res.captionContainer {
  width: 46%;
}

#bottom.captionContainer {
  bottom: auto;
  left: auto;
  width: 60%;
  margin-top: 3vw;
}

.servCaptionTitle {
  font-size: 2.5vw;
  font-weight: 700;
  letter-spacing: 2px;
}

#bottom.servCaptionTitle {
  font-size: 2.3vw;
  letter-spacing: 3px;
}

.servCaption {
  font-size: 1.2vw;
  letter-spacing: 1px;
  margin-top: 0.5rem;
}

#bottom.servCaption {
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 2vw;
  margin-top: 1.5vw;
  padding: 0 3vw;
  letter-spacing: 1.5px;
}

.commLocContainer {
  position: absolute;
  color: white;
  bottom: 10vh;
  left: 2vw;
  letter-spacing: 1px;
  font-size: 0.7vw;
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.commTextBody {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

#center.commTextBody {
  align-items: center;
  text-align: center;
}

#paragraph.commTextBody {
  margin: 0 3rem;
}

#center.commTextBody.CTA {
  margin-top: 4rem;
}

.servTextParagraph {
  line-height: 1.6vw;
}

#comm.servTextParagraph {
  width: 105%;
}

#res.servTextParagraph {
  margin: 0 7rem;
}

.commCTATitle {
  font-weight: 900;
  font-size: 1.1vw;
  margin-top: 3rem;
}

#bottom.commCTATitle {
  margin-top: -2rem;
}

.commButtonContact {
  margin-bottom: 2rem;
}

.resButtonContact {
  margin: 2rem 0;
}

#noSpace.resButtonContact {
  margin-bottom: 2rem;
  margin-top: 0;
}

.servSlider {
  width: 60%;
}


.servGalleryRedir {
  letter-spacing: 0px;
  cursor: pointer;
  position: relative;
  font-weight: 700;
}

.servGalleryRedir:hover {
  color: #7da07d;
}

.servParaTitle {
  font-weight: 700;
  font-size: 1.1vw;
  margin-bottom: -2rem;
  margin-top: 3rem;
}

.servParagraph {
  line-height: 1.6vw;
  padding-right: 3rem;
}

.commIndentedList {
  margin-left: 5rem;
}

.certs {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.certPic {
  height: 12vw;
}

.resGrass {
  width: 90%;
  display: flex;
  justify-content: center;
  position: relative;
}

.resGrass img {
  min-width: 90%;
  height: 45vw;
}

.contractBoxContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 5%;
  margin-top: -2vw;
}

.contractBox {
  width: 45vw;
  height: 8.5vw;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contractParagraph {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  color: #222222;
  gap: 0.4vw;
}

.contractTitle {
  font-weight: 700;
  font-size: 1.1vw;
}

.contractCaption {
  font-size: 0.9vw;
  line-height: 1.6vw;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .heroImgContainer {
    height: 94vh;
  }

  .captionContainer {
    left: 11vw;
  }

  .commLocContainer {
    top: 44vw;
  }

  #comm.servTextParagraph {
    margin: 0 1rem;
  }

  #res.servTextParagraph {
    margin: 0 7rem;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .captionContainer {
    bottom: 26vh;
  }

  #comm.servTextParagraph {
    width: 115%;
  }

  #paragraph.commTextBody {
    margin: 0 1.5rem;
  }

  #res.servTextParagraph {
    margin: 0 1rem;
  }

  .servParaTitle {
    margin-bottom: -1rem;
  }

  #bottom.servCaption {
    padding: 0 0.5vw;
    margin-top: 1vw;
  }

  #bottom.captionContainer {
    width: 61%;
  }

  .contractBoxContainer {
    gap: 2vw;
    margin-top: -2vw;
  }

  .contractBox {
    height: 9vw;
  }

  #bottom.commCTATitle {
    margin-top: -3rem;
  }

  .commButtonContact {
    margin-bottom: 1rem;
  }

  .certs {
    margin-bottom: -3rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .captionContainer {
    top: 65vh;
  }

  .captionContainer.blur {
    filter: blur(2px);
    top: 65vh;
  }

  .commLocContainer {
    top: 85vh;
    letter-spacing: 0.41px;
    font-size: 1vw;
  }

  .commLocContainer.blur {
    filter: blur(2px);
    top: 85vh;
  }

  .servCaptionTitle {
    font-size: 3.3vw;
    letter-spacing: 0.41px;
  }

  .servCaption {
    font-size: 1.5vw;
    letter-spacing: 0.41px;
  }

  .servicesContainer {
    margin: 0;
    font-size: 1.5vw;
    letter-spacing: -0.24px;
  }

  #center.commTextBody {
    margin: 5rem 9rem;
    margin-bottom: 0rem;
  }

  #paragraph.commTextBody {
    margin: 0 5rem;
  }

  .commCTATitle {
    font-size: 1.7vw;
    margin-top: 2rem;
    word-spacing: 1px;
  }

  .servSlider {
    width: 80%;
  }

  .servGalleryRedir {
    letter-spacing: 0px;
    word-spacing: 1px;
    margin-top: 1rem;
  }

  .servParaTitle {
    margin-bottom: -0.5rem;
    margin-top: 2rem;
    font-size: 1.6vw;
  }
  
  .servParagraph {
    padding-right: 0;
    line-height: 2.2vw;
  }

  .commIndentedList {
    line-height: 3vw;
  }
  
  .certPic {
    height: 20vw;
  }

  #comm.servTextParagraph {
    width: 100%;
    line-height: 2.2vw;
  }

  #res.servTextParagraph {
    margin: 0;
    line-height: 2.2vw;
  }

  #bottom.servCaption {
    line-height: 1.8vw;
    letter-spacing: 1px;
    word-spacing: 0.5px;
  }

  .contractTitle {
    font-size: 1.2vw;
  }
  
  .contractCaption {
    font-size: 1vw;
  }

  .navigateButton {
    font-size: 1.1vw;
    width: 12vw;
    height: 3vw;
  }

  #bottom.commButtonContact {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .heroImgContainer {
    height: 50vh;
  }

  .captionContainer {
    top: 33vh;
  }

  .commLocContainer {
    top: 46vh;
    letter-spacing: 0.41px;
    font-size: 1.2vw;
  }

  .captionContainer.blur {
    filter: blur(2px);
    top: 33vh;
  }

  .commLocContainer.blur {
    filter: blur(2px);
    top: 46vh;
  }

  .servCaptionTitle {
    font-size: 4.4vw;
    letter-spacing: 0.41px;
  }
  
  .servCaption {
    font-size: 2vw;
    letter-spacing: 0.41px;
  }

  .servicesContainer {
    margin: 0;
    font-size: 2vw;
    letter-spacing: -0.24px;
  }

  #center.commTextBody {
    margin: 5rem 2rem;
    margin-bottom: 0rem;
  }

  #center.commTextBody.CTA {
    margin-bottom: 5rem;
  }

  .servGalleryRedir {
    letter-spacing: 0px;
    word-spacing: 1px;
    margin-top: 1rem;
  }

  #paragraph.commTextBody {
    margin: 0 3.3rem;
  }

  .commCTATitle {
    font-size: 2.2vw;
    margin-top: 2rem;
    word-spacing: 1px;
  }

  .servParaTitle {
    margin-bottom: -0.5rem;
    margin-top: 2rem;
    font-size: 2.2vw;
  }
  
  .servParagraph {
    padding-right: 0;
    line-height: 3vw;
  }

  .commIndentedList {
    line-height: 4vw;
  }

  #comm.servTextParagraph {
    width: 90%;
    line-height: 3vw;
  }

  #res.servTextParagraph {
    margin: 0;
    line-height: 3vw;
  }

  #noSpace.resButtonContact {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .navigateButton {
    font-size: 2vw;
    width: 22vw;
    height: 5.5vw;
  }

  #bottom.commButtonContact {
    margin-bottom: 0;
  }

  .certPic {
    height: 25vw;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .heroImgContainer {
    height: 50vh;
  }

  #comm.heroImg {
    object-position: 10%;
  }

  #res.heroImg {
    object-position: 92%;
  }

  .captionContainer {
    top: 35vh;
  }

  #comm.captionContainer {
    width: 50%;
  }

  #res.captionContainer {
    width: 60%;
  }

  .commLocContainer {
    top: 46vh;
    letter-spacing: 0.41px;
    font-size: 2.1vw;
  }

  .captionContainer.blur {
    filter: blur(2px);
    top: 35vh;
  }

  .commLocContainer.blur {
    filter: blur(2px);
    top: 46vh;
  }

  .servCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .servCaption {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  #bottom.servCaption {
    padding: 0 7vw;
  }

  .servicesContainer {
    margin: 0;
    font-size: 3.5vw;
    letter-spacing: -0.24px;
  }

  #center.commTextBody {
    margin: 3rem 0rem;
    margin-bottom: 0rem;
  }

  #center.commTextBody.CTA {
    margin-bottom: 2rem;
  }

  .servGalleryRedir {
    letter-spacing: 0px;
    word-spacing: 1px;
    margin-top: 2rem;
  }

  #paragraph.commTextBody {
    margin: 0 2rem;
  }

  .commCTATitle {
    font-size: 4vw;
    margin-top: 2rem;
    margin-bottom: 1rem;
    word-spacing: 1px;
  }

  .servSlider {
    width: 80%;
  }

  .servParaTitle {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    font-size: 4vw;
  }
  
  .servParagraph {
    padding-right: 0;
    line-height: 5vw;
  }

  .commIndentedList {
    line-height: 7vw;
  }

  #comm.servTextParagraph {
    width: 90%;
    line-height: 5vw;
    margin-bottom: 1rem;
  }

  #res.servTextParagraph {
    margin: 0 2.4rem 1rem 2.4rem;
    line-height: 5vw;
  }

  #noSpace.resButtonContact {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .navigateButton {
    font-size: 2.5vw;
    width: 32vw;
    height: 8vw;
  }

  #bottom.commButtonContact {
    margin-bottom: 0;
  }

  .certPic {
    height: 35vw;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .heroImgContainer {
    height: 50vh;
    height: 50dvh;
  }

  #comm.heroImg {
    object-position: 10%;
  }

  #res.heroImg {
    object-position: 92%;
  }

  .captionContainer {
    top: 35vh;
    top: 35dvh;
  }

  #comm.captionContainer {
    width: 50%;
  }

  #res.captionContainer {
    width: 60%;
  }

  .commLocContainer {
    top: 46vh;
    top: 46dvh;
    letter-spacing: 0.41px;
    font-size: 2.1vw;
  }

  .captionContainer.blur {
    filter: blur(2px);
    top: 35vh;
    top: 35dvh;
  }

  .commLocContainer.blur {
    filter: blur(2px);
    top: 46vh;
    top: 46dvh;
  }

  .servCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .servCaption {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  #bottom.servCaption {
    padding: 0 7vw;
  }

  .servicesContainer {
    margin: 0;
    font-size: 3.5vw;
    letter-spacing: -0.24px;
  }

  #center.commTextBody {
    margin: 3rem -1rem;
    margin-bottom: 0rem;
  }

  #center.commTextBody.CTA {
    margin-bottom: 2rem;
  }

  .servGalleryRedir {
    letter-spacing: 0px;
    word-spacing: 1px;
    margin-top: 2rem;
  }

  #paragraph.commTextBody {
    margin: 0 2rem;
  }

  .commCTATitle {
    font-size: 4vw;
    margin-top: 2rem;
    margin-bottom: 1rem;
    word-spacing: 1px;
  }

  .servSlider {
    width: 80%;
  }

  .servParaTitle {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    font-size: 4vw;
  }
  
  .servParagraph {
    padding-right: 0;
    line-height: 5vw;
  }

  .commIndentedList {
    line-height: 7vw;
  }

  #comm.servTextParagraph {
    width: 90%;
    line-height: 5vw;
    margin-bottom: 1rem;
  }

  #res.servTextParagraph {
    margin: 0 2.5rem 1rem 2.5rem;
    line-height: 5vw;
  }

  #noSpace.resButtonContact {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .navigateButton {
    font-size: 2.5vw;
    width: 32vw;
    height: 8vw;
  }

  #bottom.commButtonContact {
    margin-bottom: 0;
  }

  .certPic {
    height: 35vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .heroImgContainer {
    height: 50vh;
    height: 50dvh;
  }

  #comm.heroImg {
    object-position: 10%;
  }

  #res.heroImg {
    object-position: 92%;
  }

  .captionContainer {
    top: 32vh;
    top: 32dvh;
  }

  #comm.captionContainer {
    width: 55%;
  }

  #res.captionContainer {
    width: 70%;
  }

  .commLocContainer {
    top: 45vh;
    top: 45dvh;
    letter-spacing: 0.41px;
    font-size: 2.1vw;
  }

  .captionContainer.blur {
    filter: blur(2px);
    top: 32vh;
    top: 32dvh;
  }

  .commLocContainer.blur {
    filter: blur(2px);
    top: 45vh;
    top: 45dvh;
  }

  .servCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .servCaption {
    font-size: 3.5vw;
    letter-spacing: 0.41px;
    margin-top: 0.25rem;
  }

  #bottom.servCaption {
    padding: 0 7vw;
  }

  .servicesContainer {
    margin: 0;
    font-size: 4vw;
    letter-spacing: -0.24px;
  }

  #center.commTextBody {
    margin: 2rem;
    margin-bottom: 0rem;
  }

  #center.commTextBody.CTA {
    margin-bottom: 2rem;
  }

  .servGalleryRedir {
    letter-spacing: 0px;
    word-spacing: 1px;
    margin-top: 2rem;
  }

  #paragraph.commTextBody {
    margin: 0 2rem;
  }

  .commCTATitle {
    font-size: 4.5vw;
    margin-top: 2rem;
    margin-bottom: 1rem;
    word-spacing: 1px;
  }

  .servSlider {
    width: 80%;
  }

  .servParaTitle {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    font-size: 4vw;
  }
  
  .servParagraph {
    padding-right: 0;
    line-height: 5vw;
  }

  .commIndentedList {
    line-height: 7vw;
  }

  #comm.servTextParagraph {
    width: 100%;
    margin-bottom: 1rem;
    line-height: 6vw;
  }

  #res.servTextParagraph {
    margin: 0 -1.25rem;
    margin-bottom: 1rem;
    line-height: 6vw;
  }

  #noSpace.resButtonContact {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .navigateButton {
    font-size: 2.75vw;
    width: 32vw;
    height: 8vw;
  }

  #bottom.commButtonContact {
    margin-bottom: 0;
  }

  .certPic {
    height: 35vw;
  }
}
.aboutContainer {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.aboutPicContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

.aboutCaptions {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 26vh;
  left: 9vw;
}

.aboutCaptionTitle {
  font-size: 2.5vw;
  font-weight: bold;
  letter-spacing: 2px;
}

.aboutSubCaption {
  font-size: 1.2vw;
  margin-top: 0.5rem;
  letter-spacing: 1px;
}

.aboutViewContainer {
  display: flex;
  padding: 5rem 7rem;
  flex-direction: column;
  gap: 3.5rem;
  word-spacing: 0.1rem;
}

.aboutParagraphCaption {
  color: #474747;
  font-weight: 700;
  font-size: 1.1vw;
  margin-bottom: -2rem;
}

.aboutParagraph {
  color: #474747;
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.6vw;
  letter-spacing: 0.5px;
}

.aboutButtonCenter {
  display: flex;
  justify-content: center;
}

.aboutBlur {
  filter: blur(2px);
  z-index: -1;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .aboutPicContainer {
    height: 94vh;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .aboutCaptions {
    bottom: 26vh;
    gap: 0.2rem;
  }

  .aboutParagraphCaption {
    margin-bottom: -2.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .aboutCaptions {
    top: 65vh;
  }

  .aboutCaptions.blur {
    filter: blur(2px);
    bottom: 65vh;
  }

  .aboutCaptionTitle {
    font-size: 3.3vw;
    letter-spacing: 0.41px;
  }
  
  .aboutSubCaption {
    font-size: 1.5vw;
    letter-spacing: 0.41px;
  }

  .aboutViewContainer {
    gap: 2.75rem;
    word-spacing: 0.1rem;
    padding: 5rem 6rem;
  }

  .aboutParagraphCaption {
    margin-bottom: -1rem;
  }

  .aboutParagraphCaption {
    font-size: 1.6vw;
    margin-bottom: -1rem;
  }

  .aboutParagraph {
    font-size: 1.5vw;
    line-height: 2.2vw;
    letter-spacing: -0.24px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .aboutPicContainer {
    height: 50vh;
    height: 50dvh;
  }

  .aboutCaptions {
    top: 33vh;
    top: 33dvh;
  }

  .aboutCaptions.blur {
    filter: blur(2px);
    top: 33vh;
    top: 33dvh;
  }

  .aboutCaptionTitle {
    font-size: 4.4vw;
    letter-spacing: 0.41px;
  }
  
  .aboutSubCaption {
    font-size: 2vw;
    letter-spacing: 0.41px;
  }

  .aboutViewContainer {
    gap: 2.75rem;
    word-spacing: 0.1rem;
    padding: 5rem 5rem;
  }

  .aboutParagraphCaption {
    font-size: 2.2vw;
    margin-bottom: -1rem;
  }

  .aboutParagraph {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: -0.24px;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .aboutPicContainer {
    height: 50vh;
    height: 50dvh;
  }

  .aboutCaptions {
    top: 35vh;
    top: 35dvh;
  }

  .aboutCaptions.blur {
    filter: blur(2px);
    top: 35vh;
    top: 35dvh;
  }

  .aboutCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .aboutSubCaption {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  .aboutViewContainer {
    gap: 2rem;
    word-spacing: 0.1rem;
    padding: 3rem 3rem;
  }

  .aboutParagraphCaption {
    font-size: 4vw;
    margin-bottom: -1rem;
  }

  .aboutParagraph {
    font-size: 3.5vw;
    line-height: 5vw;
    letter-spacing: -0.24px;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .aboutPicContainer {
    height: 50vh;
    height: 50dvh;
  }

  #aboutUs.heroImg {
    object-position: 70%;
  }

  .aboutCaptions {
    top: 35vh;
    top: 35dvh;
  }

  .aboutCaptions.blur {
    filter: blur(2px);
    top: 35vh;
    top: 35dvh;
  }

  .aboutCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .aboutSubCaption {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  .aboutViewContainer {
    gap: 2rem;
    word-spacing: 0.1rem;
    padding: 3rem 2rem;
  }

  .aboutParagraphCaption {
    font-size: 4vw;
    margin-bottom: -1rem;
  }

  .aboutParagraph {
    font-size: 3.5vw;
    line-height: 5vw;
    letter-spacing: -0.24px;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .aboutPicContainer {
    height: 50vh;
    height: 50dvh;
  }

  #aboutUs.heroImg {
    object-position: 70%;
  }

  .aboutCaptions {
    top: 32vh;
    top: 32dvh;
  }

  .aboutCaptions.blur {
    filter: blur(2px);
    top: 32vh;
    top: 32dvh;
  }

  .aboutCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .aboutSubCaption {
    font-size: 3.5vw;
    letter-spacing: 0.41px;
    margin-top: 0.25rem;
  }

  .aboutViewContainer {
    gap: 1.5rem;
    word-spacing: 0.1rem;
    padding: 2rem 1.75rem;
  }

  .aboutParagraphCaption {
    font-size: 4.5vw;
    margin-bottom: -1rem;
    word-spacing: 0.05rem;
  }

  .aboutParagraph {
    font-size: 4vw;
    line-height: 6vw;
    letter-spacing: -0.24px;
  }
}
.navbar {
  font-size: 0.8vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8rem;
}

.logo {
  width: 12vw;
  height: 100%;
  margin-left: 17%;
  margin-top: 10%;
  cursor: pointer;
}

.logo.blur {
  filter: blur(2px);
}

.navContainer {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4vw;
  margin-right: 5rem;
}

.navItem {
  position: relative; /* for the transition */
  cursor: pointer;
  letter-spacing: 2px;
}

#black.navItem {
  color: #222222;
}

#white.navItem {
  color: #ffffff;
}

.navItem::after {
  content: "";
  width: 0;
  height: 0.05rem;
  left: 0;
  bottom: -0.15rem;
  position: absolute;
  transition: width 0.35s ease 0s;
}

#black.navItem::after {
  background-color: #222222;
}

#white.navItem::after {
  background-color: white;
}

.navItem:hover::after {
  width: 100%;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .logo {
    margin-top: 20%;
    width: 11vw;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2559px) {
  /* placeholder media query for development, 1920p is original design */
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .navbar {
    height: 7rem;
  }

  .navContainer {
    margin-right: 3.5rem;
  }

  .logo {
    margin-left: 15%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .logo {
    margin-top: 0;
    width: 13vw;
  }

  .navbar {
    font-size: 1.2vw;
  }

  .navContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    width: 20%;
    max-height: 0;
    margin-right: 0;
    gap: 3rem;
    padding-top: 2rem;
    z-index: 1;
  }

  .navContainer.hide {
    overflow: hidden;
  }

  .navContainer.show {
    height: 100vh;
    max-height: 9999px;
    background-color: #222222;
    animation: slideInRight;
    animation-duration: 1s;
  }

  .mobileNavClose {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 3rem;
    margin-bottom: 1vw;
    font-size: 1.5vw;
  }

  .navMenu {
    font-size: 1.4vw;
    font-weight: 300;
    cursor: pointer;
  }

  .navMenuButton {
    font-size: 2.5vw;
    cursor: pointer;
    margin-right: 4rem;
  }

  .navItem {
    position: static;
  }

  #black.navItem {
    color: #ffffff;
  }

  .navItem:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .logo {
    margin-top: 0;
    width: 16vw;
  }

  .navbar {
    font-size: 1.5vw;
  }

  .navContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    max-height: 0;
    margin-right: 0;
    gap: 3rem;
    padding-top: 2rem;
    z-index: 1;
  }

  .navContainer.hide {
    overflow: hidden;
  }

  .navContainer.show {
    height: 100vh;
    max-height: 9999px;
    background-color: #222222;
    animation: slideInRight;
    animation-duration: 1s;
  }

  .mobileNavClose {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-bottom: 0;
    font-size: 2vw;
  }

  .navMenu {
    font-size: 1.75vw;
    font-weight: 300;
    cursor: pointer;
  }

  .navMenuButton {
    font-size: 3vw;
    cursor: pointer;
    margin-right: 4rem;
  }

  .navItem {
    position: static;
  }

  #black.navItem {
    color: #ffffff;
  }

  .navItem:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .logo {
    margin-top: 0;
    width: 27vw;
  }

  .navbar {
    font-size: 3vw;
  }

  .navContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    max-height: 0;
    margin-right: 0;
    gap: 3rem;
    padding-top: 2rem;
    z-index: 1;
  }

  .navContainer.hide {
    overflow: hidden;
  }

  .navContainer.show {
    height: 100vh;
    max-height: 9999px;
    background-color: #222222;
    animation: slideInRight;
    animation-duration: 1s;
  }

  .mobileNavClose {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-bottom: 0;
    font-size: 3vw;
  }

  .navMenu {
    font-size: 3.75vw;
    font-weight: 300;
    cursor: pointer;
  }

  .navMenuButton {
    font-size: 4vw;
    cursor: pointer;
    margin-right: 2rem;
  }

  .navItem {
    position: static;
    margin-left: -1rem;
  }

  #black.navItem {
    color: #ffffff;
  }

  .navItem:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .logo {
    margin-top: 0;
    width: 27vw;
  }

  .navbar {
    font-size: 3vw;
    height: 6rem;
  }

  .navContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    max-height: 0;
    margin-right: 0;
    gap: 2.5rem;
    padding-top: 2rem;
    z-index: 1;
  }

  .navContainer.hide {
    overflow: hidden;
  }

  .navContainer.show {
    height: 100vh;
    max-height: 9999px;
    background-color: #222222;
    animation: slideInRight;
    animation-duration: 1s;
  }

  .mobileNavClose {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-bottom: 0;
    font-size: 4vw;
  }

  .navMenu {
    font-size: 3.75vw;
    font-weight: 300;
    cursor: pointer;
  }

  .navMenuButton {
    font-size: 4vw;
    cursor: pointer;
    margin-right: 2rem;
  }

  .navItem {
    position: static;
    margin-left: -1rem;
  }

  #black.navItem {
    color: #ffffff;
  }

  .navItem:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .logo {
    margin-top: 0;
    width: 29vw;
  }

  .navbar {
    font-size: 3vw;
    height: 3rem;
  }

  .navContainer {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    max-height: 0;
    margin-right: 0;
    gap: 1.5rem;
    padding-top: 2rem;
    z-index: 1;
  }

  .navContainer.hide {
    overflow: hidden;
  }

  .navContainer.show {
    height: 100vh;
    max-height: 9999px;
    background-color: #222222;
    animation: slideInRight;
    animation-duration: 1s;
  }

  .mobileNavClose {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-bottom: 0;
    font-size: 4vw;
  }

  .navMenu {
    font-size: 4.25vw;
    font-weight: 300;
    cursor: pointer;
  }

  .navMenuButton {
    font-size: 5vw;
    cursor: pointer;
    margin-right: 2rem;
  }

  .navItem {
    position: static;
    margin-left: -1rem;
  }

  #black.navItem {
    color: #ffffff;
  }

  .navItem:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
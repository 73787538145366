.joinContainer {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.joinPicContainer {
  height: 92vh;
}

#join_us.heroImg {
  object-position: 10%;
}

.joinCaption {
  position: absolute;
  color: white;
  bottom: 19vh;
  left: 9vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.joinCaptionTitle {
  font-size: 2.5vw;
  font-weight: bold;
  letter-spacing: 2px;
}

.joinCaptionSub {
  font-size: 1.2vw;
  letter-spacing: 1px;
  margin-top: 0.5rem;
}

.joinViewContainer {
  display: flex;
  padding: 5rem 19rem;
  flex-direction: column;
  gap: 3.5rem;
  word-spacing: 0.1rem;
  text-align: center;
}

.joinContactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.joinParagraphCaption {
  color: #474747;
  font-weight: 700;
  font-size: 1vw;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.joinParagraph {
  color: #474747;
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.6vw;
  letter-spacing: 1px;
}

a[href^="tel:"] {
  text-decoration: none;
}

a[href^="tel:"]:hover {
  text-decoration: underline;
}

a[href^="mailto:"] {
  color: #222222;
  text-decoration: none;
}

a[href^="mailto:"]:hover {
  text-decoration: underline;
}

.joinBlur {
  filter: blur(2px);
  z-index: -1;
}

.contactFormContainer.join {
  position: relative;
  background-color: #f2f2f2;
  width: 50rem;
  top: 2rem;
}

.contactCommentForm.noReq::after {
  content: "";
}

input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.joinFileButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.contactFormInput.join .MuiInputLabel-standard {
  overflow: visible;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .joinPicContainer {
    height: 94vh;
  }

  .joinCaption {
    top: 36vw;
    left: 11vw;
  }

  .joinViewContainer {
    padding: 6rem 25rem;
  }

  .contactFormContainer.join {
    width: 55rem;
  }

  .contactFormTitle.join {
    font-size: 2.5rem;
  }

  .contactFormSubTitle.join {
    font-size: 1.5rem;
  }

  .requiredField.join {
    font-size: 1.5rem;
  }

  .contactFormInput.join .MuiInputLabel-standard {
    font-size: 1.25rem;
  }

  .contactFormInput.join .MuiInput-input {
    font-size: 1.25rem;
  }

  .contactFormInput.join .MuiInputLabel-outlined {
    font-size: 1.25rem;
  }

  .contactFormInput.join .MuiFormLabel-asterisk {
    font-size: 1.25rem;
  }

  .contactFormInput.join .MuiOutlinedInput-input {
    font-size: 1.25rem;
  }

  .contactCommentForm.join {
    font-size: 1.5rem;
  }

  .captchaWrapper {
    transform: scale(1.1);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .joinCaption {
    bottom: 22vh;
  }

  .joinViewContainer {
    padding: 5rem 10rem;
  }

  .contactFormContainer.join {
    width: 45rem;
  }

  .joinFileButton {
    margin-bottom: 1rem;
  }

  .contactFormInput.join .MuiOutlinedInput-input {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  #join_us.heroImg {
    object-position: 0%;
  }

  .joinViewContainer {
    padding: 5rem 3.5rem;
  }

  .joinCaption {
    top: 65vh;
  }

  .joinCaption.blur {
    filter: blur(2px);
    bottom: 65vh;
  }

  .joinCaptionTitle {
    font-size: 3.3vw;
    letter-spacing: 0.41px;
  }
  
  .joinCaptionSub {
    font-size: 1.5vw;
    letter-spacing: 0.41px;
  }

  .joinParagraph {
    font-size: 1.5vw;
    line-height: 2.2vw;
    letter-spacing: -0.24px;
  }

  .joinParagraphCaption {
    font-size: 1.6vw;
  }

  a[href^="tel:"] {
    text-decoration: underline;
  }
  
  a[href^="mailto:"] {
    text-decoration: underline;
  }

  .contactFormContainer.join {
    width: 35rem;
  }

  .contactFormInput.join .MuiOutlinedInput-input {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  #join_us.heroImg {
    object-position: 0%;
  }
  
  .joinPicContainer {
    height: 50vh;
  }

  .joinViewContainer {
    padding: 5rem 3.2rem;
    height: auto;
  }

  .joinCaption {
    top: 35vh;
  }

  .joinCaption.blur {
    filter: blur(2px);
    top: 35vh;
  }

  .joinCaptionTitle {
    font-size: 4.4vw;
    letter-spacing: 0.41px;
  }
  
  .joinCaptionSub {
    font-size: 2vw;
    letter-spacing: 0.41px;
  }

  .joinParagraph {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: -0.24px;
  }

  .joinParagraphCaption {
    font-size: 2.2vw;
  }

  a[href^="tel:"] {
    text-decoration: underline;
  }
  
  a[href^="mailto:"] {
    text-decoration: underline;
  }

  .contactFormContainer.join {
    width: 35rem;
  }

  .contactFormInput.join .MuiOutlinedInput-input {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  #join_us.heroImg {
    object-position: 0%;
  }
  
  .joinPicContainer {
    height: 60vh;
    height: 60dvh;
  }

  .joinViewContainer {
    padding: 3rem 2rem;
    margin-bottom: 1rem;
    gap: 2.5rem;
  }

  .joinCaption {
    top: 45vh;
    top: 45dvh;
  }

  .joinCaption.blur {
    filter: blur(2px);
    top: 45vh;
    top: 45dvh;
  }

  .joinCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .joinCaptionSub {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  .joinParagraph {
    font-size: 3.5vw;
    line-height: 5vw;
    letter-spacing: -0.24px;
  }

  .joinContactUs {
    margin-top: -1rem;
  }

  .joinParagraphCaption {
    font-size: 4vw;
    margin-top: 2rem;
  }

  a[href^="tel:"] {
    text-decoration: underline;
  }
  
  a[href^="mailto:"] {
    text-decoration: underline;
  }

  .contactFormContainer.join {
    width: 25rem;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  #join_us.heroImg {
    object-position: 0%;
  }
  
  .joinPicContainer {
    height: 62vh;
    height: 62dvh;
  }

  .joinViewContainer {
    padding: 3rem 1.5rem;
    margin-bottom: 1rem;
    gap: 2rem;
  }

  .joinCaption {
    top: 47vh;
    top: 47dvh;
  }

  .joinCaption.blur {
    filter: blur(2px);
    top: 47vh;
    top: 47dvh;
  }

  .joinCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .joinCaptionSub {
    font-size: 3vw;
    letter-spacing: 0.41px;
  }

  .joinParagraph {
    font-size: 3.5vw;
    line-height: 5vw;
    letter-spacing: -0.24px;
  }

  .joinContactUs {
    margin-top: -1rem;
  }

  .joinParagraphCaption {
    font-size: 4vw;
    margin-top: 3rem;
  }

  a[href^="tel:"] {
    text-decoration: underline;
  }
  
  a[href^="mailto:"] {
    text-decoration: underline;
  }

  .contactFormContainer.join {
    width: 22rem;
  }

  .contactFormInput.join .MuiInputLabel-outlined {
    font-size: 1rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  #join_us.heroImg {
    object-position: 72%;
  }
  
  .joinPicContainer {
    height: 60vh;
    height: 60dvh;
  }

  .joinViewContainer {
    padding: 2rem 1.1rem;
    margin-bottom: 2rem;
    gap: 1.5rem;
  }

  .joinCaption {
    top: 42vh;
    top: 42dvh;
  }

  .joinCaption.blur {
    filter: blur(2px);
    top: 42vh;
    top: 42dvh;
  }

  .joinCaptionTitle {
    font-size: 6vw;
    letter-spacing: 0.41px;
  }
  
  .joinCaptionSub {
    font-size: 3.5vw;
    letter-spacing: 0.41px;
    margin-top: 0.25rem;
  }

  .joinParagraph {
    font-size: 4vw;
    line-height: 6vw;
    letter-spacing: -0.24px;
  }

  .joinContactUs {
    margin-top: -1rem;
  }

  .joinParagraphCaption {
    font-size: 4.5vw;
    margin-top: 2rem;
  }

  a[href^="tel:"] {
    text-decoration: underline;
  }
  
  a[href^="mailto:"] {
    text-decoration: underline;
  }

  .contactFormContainer.join {
    width: 19rem;
  }

  .contactFormInput.join .MuiOutlinedInput-input {
    font-size: 0.8rem;
  }
}
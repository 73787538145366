.sitemapContainer {
  display: flex;
  flex-direction: row;
  background-color: #222222;
  height: 13vw;
  width: 100%;
  letter-spacing: 0.1rem;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topContainer {
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 100%;
  font-size: 0.75vw;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 100%;
  color: white;
  gap: 1rem;
}

.logo2 {
  width: 40%;
}

.phoneIconContainer {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.phoneNumbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  word-spacing: 0.2rem;
}

a[href^="tel:"] {
  color: white;
  text-decoration: underline;
}

.linkContainer {
  display: flex;
  width: 35vw;
  height: 100%;
  align-items: flex-end;
  margin: 0rem 5rem;
}

.links {
  display: flex;
  flex-flow: column wrap;
  list-style: none;
  color: white;
  gap: 2vw;
  column-gap: 5rem;
  cursor: pointer;
  height: 80%;
  width: 100%;
}

.bottomContainer {
  display: flex;
  height: 15%;
  align-items: center;
  justify-content: center;
}

.trademark {
  color: #878787;
  font-size: 0.6vw;
  margin-left: 3rem;
}

.arrowContainer {
  width: 5vw;
}

@media screen and (min-width: 2560px) and (max-width: 3760px) {
  .sitemapContainer {
    height: 12vw;
  }

  .topContainer {
    font-size: 0.7vw;
  }

  .trademark {
    font-size: 0.55vw;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .sitemapContainer {
    height: 12vw;
  }

  .topContainer {
    font-size: 0.7vw;
  }

  .trademark {
    font-size: 0.5vw;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .sitemapContainer {
    height: 15vw;
  }

  .topContainer {
    font-size: 1vw;
  }

  .contactInfo {
    gap: 0.5rem;
  }

  .phoneNumbers {
    gap: 0.25rem;
    word-spacing: 0.1rem;
  }

  .linkContainer {
    width: 45vw;
    margin: 0rem 4rem;
  }

  .links {
    column-gap: 4rem;
  }

  .logo2 {
    width: 45%;
  }

  .trademark {
    font-size: 0.75vw;
    letter-spacing: 1.5px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .sitemapContainer {
    height: 20vw;
  }

  .topContainer {
    font-size: 1.3vw;
  }

  .contactInfo {
    gap: 0.5rem;
  }

  .phoneNumbers {
    gap: 0.25rem;
    word-spacing: 0.1rem;
  }

  .linkContainer {
    width: 60vw;
    margin: 0rem 1rem;
  }

  .links {
    gap: 3vw;
    column-gap: 3rem;
  }

  .logo2 {
    width: 60%;
  }

  .trademark {
    font-size: 1vw;
    letter-spacing: 1.5px;
  }
}

@media screen and (min-width: 415px) and (max-width: 699px) {
  .sitemapContainer {
    height: auto;
  }

  .topContainer {
    font-size: 3vw;
    flex-direction: column;
    height: 100%;
  }

  .contactInfo {
    gap: 1rem;
    width: auto;
    height: auto;
  }

  .phoneNumbers {
    gap: 0.25rem;
    word-spacing: 0.1rem;
  }

  .links {
    justify-content: center;
    align-items: center;
    width: auto;
    gap: 5vw;
    margin: 2rem 0 1rem 0;
    padding: 0;
  }

  .logo2 {
    width: 60%;
  }

  .bottomContainer {
    height: auto;
  }

  .trademark {
    margin: 1rem 0;
    font-size: 2vw;
    letter-spacing: 1.5px;
  }
}

@media screen and (min-width: 350px) and (max-width: 414px) {
  .sitemapContainer {
    height: auto;
  }

  .topContainer {
    font-size: 3vw;
    flex-direction: column;
    height: 100%;
  }

  .contactInfo {
    gap: 1rem;
    width: auto;
    height: auto;
  }

  .phoneNumbers {
    gap: 0.25rem;
    word-spacing: 0.1rem;
  }

  .links {
    justify-content: center;
    align-items: center;
    width: auto;
    gap: 5vw;
    margin: 2rem 0 1rem 0;
    padding: 0;
  }

  .logo2 {
    width: 60%;
  }

  .bottomContainer {
    height: auto;
  }

  .trademark {
    margin: 1rem 0;
    font-size: 2vw;
    letter-spacing: 1.5px;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .sitemapContainer {
    height: auto;
  }

  .topContainer {
    font-size: 3vw;
    flex-direction: column;
    height: 100%;
  }

  .contactInfo {
    gap: 1rem;
    width: auto;
    height: auto;
  }

  .phoneNumbers {
    gap: 0.25rem;
    word-spacing: 0.1rem;
  }

  .links {
    justify-content: center;
    align-items: center;
    width: auto;
    gap: 4.5vw;
    margin: 1rem 0 0 0;
    padding: 0;
  }

  .logo2 {
    width: 50%;
  }

  .bottomContainer {
    height: auto;
  }

  .trademark {
    margin: 1rem 0;
    font-size: 2vw;
    letter-spacing: 1.5px;
  }
}